.tabs {
    display: flex;
    justify-content: space-around;
}

.tab {
    height: 2rem;
    border: 1px solid #000;
    padding: 0.5rem 2rem;
    background-color: #cf2929;
    font-weight: 700;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer
}

.auth-wrapper {
    width: 100vw;
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-input {
    height: 2rem;
    font-size: 1.3rem;
}

.auth-button {
    width: 5rem;
    height: 2rem;
    margin-top: 1rem;
    cursor: pointer
}

.delete-wrapper {
    margin: 1rem
}

.single-item {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px dashed black;
}

.delete-item {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
}

.delete-pic {
    width: 4rem;
    margin-right: 1rem;
}

.delete-button {
    height: 2rem;
    margin-left: 1rem;
}

.form-field {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 0 0 1rem 1rem;

    &>input,
    &>textarea {
        font-size: 1.5rem
    }

    &>label {
        font-size: 1.2rem;
        margin-bottom: 0.5rem
    }

}

.upload-button {
    margin-left: 1rem;
    width: 6rem;
    height: 2rem;
    font-size: 1.2rem;
    background-color: rgba(255, 0, 0, 0.764);
}

.edit-form>div {
    width: 100%;
}
.wrapper {
    width: 100vw;
    height: 100%;
    /* background-image: url('https://i.ibb.co/Gv1kttg/background.png');
    background-size: cover; */
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
}



.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@font-face {
  font-family: 'DrippyFont';
  /* Choose a unique font name */
  src: url('./fonts/plasma-drip.brk.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  background-color: #595858;
  background-image: url('https://i.ibb.co/FxpB6pn/background.png');
  background-size: 30%;
  color: white;
}

body {
  overflow-x: hidden;

}

.title {
  /* background-color: #595858; */
  -webkit-text-stroke: 2px black;
  width: 100vw;
  margin: auto;
  display: flex;
  justify-content: center;
  height: 10rem;
}

.title-text {
  position: fixed;
  height: 10rem;
  z-index: 5;
  cursor: pointer;
}

.header-logo {
  height: 10rem;
  width: 80vw;
  max-width: 500px
}
.product-wrapper {
    /* background: #595858; */
    width: 100vw;
    height: 100%;
    overflow: hidden
}

.product-image {
    width: 300px;
    height: 300px;
    margin: 1rem;
}

.CTAs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
}


@media only screen and (min-width: 900px) {
    .product-wrapper {
        display: flex
    }

    .product-image {
        width: 30rem;
        height: 30rem;
        margin: 1rem;
    }

    .CTAs {
        flex-direction: row;
        width: 100%;
        gap: 0.2rem;
        justify-content: flex-start;

        &>button {
            margin: 0.5rem
        }
    }
}

.details {
    padding: 2rem;
}

.name {
    font-family: "DrippyFont";
    -webkit-text-stroke: 1px black;
}

.order-now {
    margin: 0 2rem;
    padding: 1rem 1.5rem;
    background: #000;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
}

.order-now-sold {
    margin: 0 2rem;
    padding: 1rem 1.5rem;
    background: #6d6d6d;
    color: white;
    border: none;
    font-size: 1.3rem;
    cursor: pointer;
    pointer-events: none;
}

.in-wishlist {
    background-color: rgb(108, 25, 25);
}

.disclaimer {
    padding: 2rem;
    color: #c5c5c4
}
.image-wrapper {
    width: 300px;
    height: 300px;
    margin: 1rem;
    position: relative;
    cursor: pointer;
}

.sold {
    /* width: 100%;
    height: 100%; */
    background: red;
    z-index: 2;
}

.card-image {
    width: 100%;
    height: 100%;
}

.sold-overlay-true {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background: rgba(255, 255, 255, 0.6); */
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sold-overlay-true::after {
    content: "SOLD OUT";
    color: white;
    font-size: 3.6rem;
    font-weight: 700;
    transform: rotate(-45deg)
}